.brand__lockup {
  a {
    img {
      height: 86px;
      width: auto;
    }
  }
}

#primary-nav {
  .level-1 {
    width: auto;
    padding: 8px 27px;

    @include breakpoint($md) {
      padding: 8px 23px;
    }

    &:last-child {
      //text-align: right;
      width: 11%;
    }

    &:first-child {
      //text-align: left;
      width: auto;
    }
  }
}

#top-nav .level-2 {
  @include breakpoint($md) {
    padding-right: 10px;
  }
}

#block-menu-block-1 .content {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-gap: 15px;
  -moz-column-gap: 15px;
  column-gap: 15px;
  margin-bottom: 10px;
}

.homeheader {
  .pane-bundle-text {
    //max-width: 1140px;
    @include breakpoint($sm){max-width: 720px;}
    @include breakpoint($md){max-width: 940px;}
    @include breakpoint($lg){max-width: 1140px;}
    display: block;
    margin: 0 auto;
  }
  .peds-home-callout {
    min-height: 485px;
    //background-image: url("../images/pediatricsbanner1.jpg");
    background-position: right top;
    background-size: cover;
    background-repeat: none;
    @include breakpoint($sm) {
      background-position: center;
    }
    .callout-text {
      min-height: 485px;
      background-color: rgba(255,255,255,0.7);
      float: left;
      width: 100%;
      @include breakpoint($sm) {
        width: 50%;
      }
      height: 495px;
      margin-top: 0;
      h2 {
        font-size: 36px;
        line-height: 1.5;
        font-family: 'Open Sans', sans-seriff;
        font-weight: 200;
        color: $wcm-dark-orange;
        opacity: 1;
        padding: 80px 20px 0 35px;
        @include breakpoint($md) {
          line-height: 64px;
          font-size: 48px;
        }
      }
    }
  }
}

.OrangeBar{
    display: block;
    background-color: $wcm-bright-orange;
    text-align: center;
    margin-top: 30px;
    padding: 0 20px;
    select {
        color: #000;
        max-width: 940px;
        width: 100%!important;
        margin: 20px 0;
        height: 50px;
        border: 1px solid $wcm-border-gray;
        box-shadow: none;
        appearance: none;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
	      background-repeat: no-repeat;
	      background-position: right 15px top 50%;
        background-size: 20px 20px;
        padding: 0 50px 0 20px;
    }
}

.callcta{
    min-height: 135px;
    line-height: 1.5;
    padding: 46px 20px;
    text-align: center;
    font-size: 26px;
    .btn--wcm{
        border-radius: 25px;
        padding: 8px 35px;
        font-size: 18px;
        min-width: 280px;
        margin-bottom: 20px;
        @include breakpoint($sm){
            display: inline;
            margin-right: 23px;
            margin-bottom: 0;
        }
    }
}

.foottwo1, .foottwo2{
    margin-top: 90px;
    margin-bottom: 80px;
}

.foottwo1{
    @include breakpoint($md){
        border-right: 1px solid #e6e7e8;
        padding-left: 120px;
    }
}

.foottwo2{
    @include breakpoint($md){
        padding-left: 100px;
    }
}

.ctarow{
    .col-md-4{
        overflow: hidden;
    }
    h2{
        float: left;
        width: 100%;
        text-align: center;
        height: 350px;
        margin-top: 0;
        font-size: 42px;
        text-shadow: 2px 2px #666;
        a{
            padding-top: 260px;
            display: block;
            width: 100%;
            height: 100%;
            float: left;
            color: #fff;

            &:after{
                display: none;
            }
            &:hover{
                transform: scale(1.1);
            }
        }
    }
    .famcta{
        h2 a{
            background: url("../images/familes2.jpg") no-repeat center center;
            background-size: cover;
        }
    }
    .rescta{
        h2 a{
            background: url("../images/research1.jpg") no-repeat center center;
            background-size: cover;
        }
    }
    .edcta{
        h2 a{
            background: url("../images/edu1.jpg") no-repeat 0 0;
            background-size: cover;
        }
    }
}

.footer3rd1{
    padding: 0;
}

.footer3rd2{
    padding: 0;
}

.footer3rd3{
    padding: 0;
}

.btn--sml, .webform-submit{
    display: inline-block;
    background-color: #cf4520;
    border-radius: 7px;
    height: 30px;
    line-height: 30px;
    padding: 0 14px;
    color: #fff;
    margin-right: 10px;
    font-size: 12px;
    &:hover{
        background-color: $wcm-bright-orange;
    }
}

.webform-submit{
    padding-right: 35px;
    padding-left: 35px;
}

.lgcol{
    @include breakpoint($md){
        max-width: 1306px;
        margin: 0 auto 55px;
        //min-height: 820px;
    }
    .col-md-8{
        @include breakpoint($md){
            padding: 0 5px 0 0;
        }
    }
    .col-md-4{
        @include breakpoint($md){
            padding: 0 0 0 5px;
        }
    }
}
.pane-news-panel-pane-5{
    .view-teaser{
        margin-bottom: 14px;
    }
    .teaser-image{
        float: left;
        width: 100%;
        @include breakpoint($sm){
            width: 48%;
            margin: 0 0 0 14px;
        }
        @include breakpoint($lg){
            width: 49%;
        }
        img{
            width: 100%;
        }
    }
    .views-field-nothing{
        float: left;
        overflow: hidden;
        background-color: #f6f7f8;
        min-height: 403px;
        width: 100%;
        padding: 20px 20px 0;
        p.post-date{
            padding: 0 0 0 40px;
        }
        @include breakpoint($sm){
            padding: 7% 0 0 6.2%;
            width: 48%;
            min-height: 0;
        }
        @include breakpoint($md){
            padding: 4% 0 0 5%;
            h3.teaser-title{
                font-size: 16px;
            }
            p, p.post-date{
                font-size: 13px;
            }
            a.btn{
                margin-top: 10px;
            }
        }
        @include breakpoint($lg){
            padding: 7% 0 0 6.2%;
            width: 49%;
            h3.teaser-title{
                font-size: 20px;
            }
            p{
                font-size: 18px;
            }
            p.post-date{
                font-size: 14px;
            }
            a.btn{
                margin-top: 17.5px;
            }
        }
    }
    h3.teaser-title{
        font-size: 20px;
        line-height: 1.5;
        margin-top: 0;
        a{
            color: #cf4520;
            &:after{
                display: none;
            }
            &:before{
                content: " ";
                float: left;
                margin: 0 10px 120px 0;
                width: 30px;
                height: 30px;
                background: url('../images/newssprite.png') 0 0 no-repeat;
                background-size: 30px 30px;
            }
        }
    }
    p.post-date{
        color: #7a7b7c;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 14px;
        padding: 25px 0 0;
    }
    p{
        padding:0 40px 0 40px;
        font-size: 18px;
        line-height: 1.5;
    }
    a.btn{
        margin-top: 17.5px;
        margin-left: 40px;
    }

    .views-row-last{
        .views-field-nothing{
            float: right;
            @include breakpoint($sm){
                margin-right: 4px;
            }
        }
        .teaser-image{
            @include breakpoint($sm){
                margin: 0 0 10px ;
            }
        }
    }
}

.pane-events-panel-pane-1{
    .views-row{
        float: left;
        height: 403px;
        margin: 0 0 14px 0;
        background-color: #f6f7f8;
        padding: 20px;
        width: 100%;
        overflow: hidden;
        .views-field-field-events-date,
        .views-field-field-date-and-time{
            padding-left: 40px;
            text-transform: uppercase;
            color: #7a7b7c;
            font-weight: bold;
            margin: 0 0 20px 0;
        }
        .views-field-body{
            padding-left: 40px;
            font-size: 18px;
        }
        .views-field-nothing{
            padding-left: 40px;
            margin-top: 17.5px;
        }

        h3{
            margin-top: 0;
            a{
                font-size: 18px;
                line-height: 1.5;
                color: $wcm-dark-orange;
                &:after{
                    display: none;
                }
                &:before{
                    content: " ";
                    float: left;
                    margin: 0 10px 120px 0;
                    width: 30px;
                    height: 30px;
                    background: url('../images/calsprite.png') 0 0 no-repeat;
                    background-size: 30px 30px;
                }
            }
        }

        @include breakpoint($md){
            padding: 35px 0 0 30px;
            h3 a{
                font-size: 16px;
            }
            .views-field-body{
                font-size: 13px;
            }
            .views-field-field-events-date{
                margin-bottom: 10px;
            }
            .views-field-field-events-date .field-content{
                font-size: 13px;
            }
        }
        @include breakpoint($lg){
            padding: 50px 20px 0 45px;
            h3 a{
                font-size: 18px;
            }
            .views-field-body{
                font-size: 18px;
            }
            .views-field-field-events-date{
                margin-bottom: 20px;
            }
            .views-field-field-events-date .field-content{
                font-size: 14px;
            }
        }
    }
}

/* RHS column style */
.pane-wcm-peds-serappbtn,
.pane-wcm-peds-servicesbtn,
.pane-wcm-peds-serctsbtn {
  margin-top: 20px;
  float: left;
  width: 100%;
  @include breakpoint ($md) {
    margin-top: 0;
  }
  .btn {
    margin: 0 auto 13px;
    display: block;
  }
}

.pane-wcm-peds-socbtns {
  float: left;
  width: 100%;
  margin-bottom: 20px;
  .socbtns {
    width: 220px;
    margin: 0 auto;
    &:before, &::after {
      content: " ";
      display: table;
    }
    .wcm-cta__tile {
      height: 40px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}

.pane-node-field-weillcornell-org-page {
  width: 100%;
  float: left;
}

.information-sidebar {
  margin-top: -35px;
  .pane-custom.pane-1 {
    width: 100%;
    float: left;
    margin: 0 0 0 20px;
    min-height: none;
    .pane-title {
      margin: 10px 0 5px;
      color: $wcm-med-gray;
      font-size: 13px;
      font-weight: 700;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
    a {
      font-size: 12px;
      line-height: 32px;
    }
  }
}

.pad10b {
  padding-bottom: 10px;
}

.contact-info {
  span {
    line-height: 24px;
  }
  .site-name {
    margin-top: 30px;
  }
  .titleline {
    margin-top: 30px;
  }
}

.wcm-cta {
  margin-bottom: 0px;
  .wcm-cta__tile {
    border-width: 0;
    width: 73px;
    height: 73px;
    margin-top: 10px;
  }
  .mailcta {
    svg {
      margin-bottom: 2px;
      margin-top: 2px;
    }
  }
  span.lnktxt {
    margin-top: 5px;
  }
}

.jump-to-top {
  margin-top: 5px;
}

.jump-to-top > a {
  border-top: 1px solid #d7d8d9;
  padding-top: 18px;
}

.jump-to-top > a span {
  border-top: 0;
}

/* Clincial Services */
.btn--orglnk{
    margin-bottom: 20px;
    background: url('../images/orglnk.svg') no-repeat 175px 5px;
    background-size: 23px;
    &:hover{
        background: url('../images/orglnkw.svg') no-repeat 175px 5px $wcm-bright-orange;
        background-size: 23px;
        &:before{
            opacity: 0;
        }
    }
}

#views-exposed-form-profiles-panel-pane-4 .select2-container{
    width: 400px !important;
    max-width: 400px !important;
}

.pane-node-field-services-programs,
.pane-node-field-diagnosis-treatment,
.pane-node-field-what-to-expect,
.pane-profiles-panel-pane-3,
.pane-node-field-related-research,
.pane-node-field-curriculum,
.pane-node-field-research,
.pane-profiles-panel-pane-5,
.pane-node-field-benefits,
.pane-node-field-resources,
.pane-node-field-what-sets-us-apart{
    border-bottom: 5px solid #d7d8d9;
    margin-bottom: 48px;
    padding-bottom: 30px;
}
.pane-node-field-curriculum{
    border-top: 5px solid #d7d8d9;
    margin-top: 48px;
    padding-top: 26px;
}

.pane-profiles-panel-pane-5{
    h3{
        margin-bottom: 30px;
    }
}

.pane-node-field-how-to-apply{
    margin-bottom: 40px;
}

.pane-profiles-panel-pane-3{
    margin-top: 30px;
}
.pane-node-field-related-research{
    h3{
        margin-bottom: 30px;
    }
    h4{
        margin-top: 30px;
    }
}

.pane-profiles-panel-pane-3, .pane-profiles-panel-pane-5{
    .row{
        margin: 0;
    }

        .views-field-field-professional-title{
            font-size: 13px;
        }
}

.pane-node-field-resources {
  h3 {
    margin-bottom: 20px;
  }
}

/* About Us */

.pane-news-panel-pane-6,
.pane-news-panel-pane-7,
.pane-news-panel-pane-8,
.pane-news-panel-pane-9,
.pane-news-panel-pane-10,
.pane-news-panel-pane-12,
.pane-news-panel-pane-13 {
  h3 {
    border-top: 5px solid #dcddde;
    padding-top: 30px;
  }
  p.teaser-title {
    font-size: 16px;
    margin-top: 30px;
  }
  p.post-date,
  .views-field-field-events-date {
    margin: 10px 0 5px;
    color: $wcm-med-gray;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  .teaser-text {
    p {
      margin-bottom: 0px;
    }
  }
  .btn--sml {
    margin: 40px 0;
    background-color: $wcm-red;
    &:hover {
      background-color: $wcm-bright-orange;
    }
  }
}

.pane-node-field-research-education {
  h3.pane-title{
    color: #555;
  }
}

.pane-node-field-related-service,
.pane-node-field-related-fellowship {
  color: #fff;
  background-color: $wcm-red;
  margin-bottom: 20px;
  a {
    color: #fff;
    border: none;
  }
}

.pane-node-field-contact-info1{
    border: 5px solid #f6f7f8;
    text-align: center;
    padding-bottom: 15px;
    margin-bottom: 50px;
    .content{
        font-size: 16px;
        line-height: 25px;
    }
    h3{
        color: #545556;
        margin-top: 15px;
        text-align: center;
    }
    .entity-field-collection-item{
        border-top: 1px solid #f6f7f8;
        padding: 10px 0;
        margin: 0 20px;
        &:first-child{
            border-top: 0;
            padding-top: 0;
        }
        &:last-child{
            padding-bottom: 0;
        }
    }
}

hr{
    border-top: 5px solid #dcddde;
    margin-top: 55px;
    margin-bottom: 45px;
}

.btn--xpad{
    padding: 0 45px;
    border-radius: 8px;
    font-size: 12px;
}

.btn--sml{
    font-size: 12px;
}

.pane-wcm-peds-fellowdrop{
    h3{
        margin-bottom: 10px;
    }
    .select2{
        max-width: 345px;
        width: 100%!important;
        margin-bottom: 30px;
    }
}

/* Research Page */

.pane-news-news-research-pane {
  p.post-date {
    margin: 10px 0 5px;
    color: #777;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
}

.pane-programs-list-panel-pane-1 {
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 20px;
  a {
    &::after {
      content: none;
    }
  }

  @include breakpoint($xs) {
    display: block;
  }
  @include breakpoint($sm) {
    display: block;
  }
  @include breakpoint($md) {
    display: none;
  }
}

.information-column {
  .pane-programs-list-panel-pane-1 {
    border: 1px solid #ddd;
    padding: 10px 0 20px 30px;
    margin-bottom: 20px;
    a {
      &::after {
        content: none;
      }
    }

    @include breakpoint($xs) {
      display: none;
    }
    @include breakpoint($sm) {
      display: none;
    }
    @include breakpoint($md) {
      display: block;
    }
  }
}

.pane-wcm-peds-research-callout {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 30px 0;

  h3 {
    margin-bottom: 10px;
  }
}

.pane-bundle-wcm-page-item {
  .wcm-pgraph-text-teaser {
    margin: 50px 0;
  }
}

.pane-news-news-research-pane {
  .pane-title {
    margin-bottom: 25px;
  }

  .view-content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include breakpoint($md) {
      flex-direction: row;
    }

    .view-teaser {
      width: 100%;

      @include breakpoint($md) {
        flex-direction: row;
        width: 33%;
        margin: 0 20px;
      }

      .teaser-image {}
    }
    .views-row-first {
      margin-left: 0;
    }
    .views-row-lasat {
      margin-right: 0;
    }
  }
}

/* Career Opportunities Page */

.view-id-career_opportunities {
  .views-row {
    padding-bottom: 1rem;
    border-bottom: 1px solid $wcm-border-gray;
    margin-bottom: 3rem;
  }
}

/* Profile List Page */

.profile-list-item__pro-title {
  .item-list {
    ul li {
      margin: 0;
      padding: 0;
    }
  }
}

/* Services Page */

.node-type-pediatric-services {
  @include breakpoint($md) {
    .wcm-pgraph-card {
      width: 50%;
    }
  }

  .pane-node-field-contact-info {
    margin: 25px 0;
    padding: 25px 0;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    text-align: center;

    .field-contact-title,
    .field-contact-devision {
      font-weight: bold;
    }
  }
}

.pane-pediatrics-services-panel-pane-1,
.pane-programs-list-panel-pane-1,
.reslinks,
.pane-fellowship-panel-pane-1 {
  .col-1 {
    @include breakpoint($md) {
      padding-right: 40px;
    }
  }
  a {
    color: $wcm-dark-orange;
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    display: block;
    padding: 15px 15px 10px 3px;
    border-bottom: 1px solid #ddd;
    &:hover {
        border-bottom: 1px solid #e7751d;
    }
    &:after {
      content: '\e80d';
      color: #e87722;
      padding-left: 10px;
      font-size: 60%;
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      display: block;
      text-decoration: inherit;
      text-align: center;
      font-variant: normal;
      text-transform: none;
      line-height: 1em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-decoration: none;
      line-height: 1.5;
      float: right;
    }
  }
}

.hero-image--full {
  width: 100%;
  height: 175px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 0 0 20px;
  @include breakpoint($sm) {
    height: 300px;
  }
}

.pane-node-field-main-image img {
  width: 100%;
  height: auto;
  margin: 0 0 20px;
}

.pane-fellowship-panel-pane-1 {
  margin-bottom: 40px;
}

.reslinks {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 80px;
    -moz-column-gap: 80px;
    column-gap: 80px;
    padding-left: 0;
    list-style: none;
    margin-top: 15px;
    li {
        width: 100%;
        &:nth-child(3) {
            a{
                border-bottom: none;
            }
        }
        &:nth-child(6) {
            a{
                border-bottom: none;
            }
        }
    }
}

.dructa{
    margin-top: 30px;
    display: inline-block;
    clear: both;
    .caption{
        margin-top: 0;
    }
}

.drurow{
    margin-top: 65px;
    h4{
        margin: 0 0 20px 0;
        a{
            color: #555;
        }
    }
    .col-md-6:first-child{
        border-right: 1px solid #d7d8d9;
        padding-right: 45px;
    }
    .col-md-6:nth-child(2){
        @include breakpoint($md){
            padding-left: 45px;
        }
    }
}

.pane-node-webform{
    margin-top: 50px;
    margin-bottom: 77px;
    label{
        margin-bottom: 10px;
    }
    .select2-container{
        width: 100%!important;
        max-width: 600px;
    }
}

.form-control {
    max-width: none;
}

.view-display-id-panel_pane_4{
    margin: 0;
}

#views-exposed-form-profiles-panel-pane-4{
    .select2-container{
        width: 265px!important;
        max-width: 265px!important;
        margin-right: 5px;
    }
    .views-exposed-form label {
        //font-weight: normal;
        font-size: 13px;
    }
}

.pane-profiles-panel-pane-4{
    h4.display-name{
        font-size: 16px;
        color: #000;
        font-weight: bold;
    }
    .views-field-field-professional-title{
        font-size: 13px;
    }
    .views-field-nothing{
        font-size: 12px;
        color: $wcm-dark-orange;
        margin-top: 5px;
    }

    .view-grouping-header{
        border-top: 5px solid #d7d8d9;
        padding-top: 40px;
        margin-bottom: 16px;
        color: $wcm-red;
        font-size: 20px;
        font-family: "1898Sans-Bold",sans-serif;
    }

    h3.grid-title{
        color: #545556;
        &:first-child{
            display: none;
        }
    }

    .view-grouping{
        &:first-child{
            .view-grouping-header{
                border-top: none;
            }
        }
    }

    .gridcol{
        margin: 20px 0;
    }
}

.pane-patient-stories-panel-pane-1{
    .grid-item{
        border-bottom: 5px solid #d7d8d9;
        max-width: 355px;
    }
    h3{
        margin-bottom: 30px;
    }
    .views-field-field-main-image{
        margin-bottom: 30px;
    }
    .views-field-field-patient-quote{

    }
    blockquote{
        font-size: 15px;
        line-height: 25px;
        border-left: 3px solid $wcm-yellow;
        color: #000;
        padding: 0 12px;

    }
    .views-field-view-node{
        margin-bottom: 33px;
        a{
            padding-left: 15px;
            &:after{
                content: '\e80d';
                color: $wcm-dark-orange;
                padding-left: 10px;
                font-size: 60%;
                vertical-align: top;
                font-family: "fontello";
                font-style: normal;
                font-weight: normal;
                speak: none;
                display: inline-block;
                text-decoration: inherit;
                text-align: center;
                font-variant: normal;
                text-transform: none;
                line-height: 1em;
                text-decoration: none;
                line-height: 2.8;
            }
        }
    }
}

blockquote{
    font-size: 25px;
    line-height: 35px;
    color: #6c6d6e;
    border-left: 5px solid $wcm-yellow;
    padding: 0 18px;
    font-weight: normal;
}

.pane-node-field-secondary-image{
    width: 50%;
    float: left;
    margin: 25px 0 40px;
}

.pane-custom.pane-1{
    margin: 25px 0 40px;
    width: 50%;
    float: left;
    min-height: 230px;
    blockquote{
        margin-bottom: 0;
    }
    p{
        border-left: 5px solid $wcm-yellow;
        padding: 25px 18px 0;
        margin: 0;
        font-size: 16px;
    }
}

.field-collection-item-field-contact-info{
    margin: 20px 0;
    .content{
        text-align: center;
        .field-name-field-contact-title,
        .field-name-field-contact-devision{
            color: #6c6d6e;
            line-height: 24px;
            font-weight: 600;
        }

        .field-name-field-serv-address-line-1{
            margin-top: 20px;
        }
        .field-label{
            display: inline;
            float: none;
            font-weight: 400;
        }
        .field-name-field-phone-no-,
        .field-name-field-fax-no-{
            font-weight: 600;
            .field-label{
                font-weight: 600;
            }
        }
    }
}

.btn--wcm:hover:before {
  left: 90%;
}

#qualfrom {
  height: 1500px;
  max-width: 100%;
  border: 0;
}

/* .ctools-collapsible-handle {
  position: relative;
  border: 1px solid #ddd;
  border-width: 1px 0 0;
  cursor: pointer;
  margin: 0;
  padding: 15px 55px 15px 10px;

  h2 {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #cf4520;
    margin: 0;
    &:after {
        content: '+';
        width: 50px;
        display: block;
        position: absolute;
        top: 15px;
        right: 0;
        font-size: 30px;
        font-weight: 600;
        text-align: center;
        border-left: 1px solid #ddd;
    }
  }
} */

/* .ctools-collapsible-handle.clsbtn h2:after {
  content: '-';
} */

/* .ctools-collapsible-container .ctools-toggle-collapsed {
  background: none;
} */

.ctools-toggle {
  display: none;
}

h3.field-label {
  margin: 0;
}

.main-content {
    table {
      width: 100%;
      max-width: 100%;
      margin-bottom: $line-height-computed;
      // Cells
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            padding: $table-cell-padding;
            line-height: $line-height-base;
            vertical-align: top;
            border-top: 1px solid $table-border-color;
          }
        }
      }
      // Bottom align for column headings
      > thead > tr > th {
        vertical-align: bottom;
        border-bottom: 2px solid $table-border-color;
      }
      // Remove top border from thead by default
      > caption + thead,
      > colgroup + thead,
      > thead:first-child {
        > tr:first-child {
          > th,
          > td {
            border-top: 0;
          }
        }
      }
      // Account for multiple tbody instances
      > tbody + tbody {
        border-top: 2px solid $table-border-color;
      }

      // Nesting
      table {
        background-color: $body-bg;
      }
    }

    table {
  border: 1px solid $table-border-color;
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border: 1px solid $table-border-color;
      }
    }
  }
  > thead > tr {
    > th,
    > td {
      border-bottom-width: 2px;
    }
  }
}

  table{
    width: 100%;
    margin:  0 0 16px 0;
    border-top-color: #f5f6f7;
    thead{
        border-color: #f5f6f7;
        tr{
            border-color: #f5f6f7;
            th{
                background-color: #f5f6f7;
                border-color: #f5f6f7;
                padding: 3px;
            }
        }
    }
    tbody{
        border-color: #f5f6f7;
        tr{
            background-color: #fff;
            &:nth-child(odd)>td{
                background-color: #fff;
            }
            td{
                border-color: #f5f6f7;
            }
        }
    }
  }
}

.pane-profiles-panel-pane-3{
    .views-field-field-pops-profile-link{
        margin-bottom: 20px;
    }
}

.media-youtube-player{
    border: 0;
}

.pane-node-field-what-sets-us-apart{
    h3{
        margin-bottom: 20px;
    }
}

/* =Default Select Elements
----------------------------------------------------------*/

.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid $wcm-border-gray;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px;
}
