/**
 * Styles targeting the Paragraph bundles used on the site
 *
 */

/* =Misc
----------------------------------------------------------*/
.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}

/* =Sections
----------------------------------------------------------*/
.paragraph-section__title {
  border-top: 5px solid $wcm-red;
  text-align: center;
  span {
    display: inline-block;
    background: $wcm-red;
    color: $wcm-white;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    margin: -1px 0 0;
    padding: 5px 20px;
    letter-spacing: 2px;
    @include breakpoint($md) {
      margin: 0;
    }
  }
}

/* =Bar Callouts
----------------------------------------------------------*/
.bar-callout .row.is-flex {
  @include breakpoint($xs) {
    display: block;
  }
}

.bar-callout {
  @include breakpoint($md) {
    .container {
      padding-right: 0;
      padding-left: 0;
      margin-left: 0;
      margin-right: 0;
    }
    .row {
      min-height: 315px;
      img {
        min-height: 315px;
      }
    }
  }
}

.bar-panel--title {
  a {
    &:after {
      content: "\e80d";
      color: $wcm-white;
    }
  }

  @include breakpoint($md) {
    a {
      &:after {
        line-height: 2;
      }
    }
  }
  @include breakpoint($sm) {
    a {
      &:after {
        line-height: 1.6;
      }
    }
  }
}

.bar-panel--title--linked {
  cursor: pointer;
}

.panel-pane {
  &:nth-child(odd) {
    .bar-panel--title {
      background-color: $wcm-dark-orange;
    }
  }

  &:nth-child(even) {
    .bar-panel--title {
      background-color: $wcm-bright-orange;
    }
  }
}

.bar-callout .row {
  &:hover {
    .bar-callout__image img {
        transform: scale(1.1);
    }
  }
}

.bar-callout__text {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  @include breakpoint($sm) {
    text-align: center;
    @include center();
    margin: 0;
    font-size: 13px;
  }
  @include breakpoint($md) {
    font-size: 15px
  }
  a {
    .external-link {
      &:after {
        @include wcm-external-icon();
      }
    }

    &:after {
      color: $wcm-white;
      line-height: 1.6;
    }
  }

  @include breakpoint($xs) {
    height: 180px;

    h4 a {
      color: $wcm-white;
    }
  }
}

.bar-callout__title {
  font-size: 30px;
  @include breakpoint($xs) {
    margin: 0 25px 0;
  }
  @include breakpoint($md) {
    margin-top: 0;
    padding-bottom: 5px;
  }
  a {
    color: $wcm-white;
    &:hover {
      // color: $wcm-bright-orange;
      text-decoration: underline;
    }
  }
}

.bar-callout__description {
  color: $wcm-white;
  margin: 5px 0 0;
  @include breakpoint($md) {
    font-size: 14px;
    line-height: 1.8;
  }
  &:hover {
    text-decoration: underline;
  }
}

.bar-callout__image {
  overflow: hidden;
  a {
    display: block;
    @include breakpoint($sm) {
      border-bottom: none;
    }
  }
}

.bar-callout__image img {
  width: 100%;
  transition: all 0.3s ease 0s;
  padding: 0;
  &:hover {
    transform: scale(1.1);
  }
}

/* =Link Callout
----------------------------------------------------------*/
.link-callout {
  position: relative;
  margin-top: 10px;
  display: flex;
  align-items: center;

  a {
    display: flex;
    color: $wcm-white;
    width: 100%;
    overflow: hidden;
    align-content: center;
    align-items: center;

    &:after {
      color: $wcm-white
    }

    img {
      width: 100%;
      transition: all 0.3s ease 0s;
      vertical-align: middle;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }

  h3 {
    position: absolute;
    top: 50%;
    text-align: center;
    transform: translateY(-50%);
    font-size: 26px;
    margin: 0;
    padding: 0;
    width: 100%;
    color: $wcm-white;
    line-height: 1.5;
    cursor: pointer;
    pointer-events: none;

    &:after {
      content: '\e80d';
      color: $wcm-white;
      font-size: 60%;
      vertical-align: middle;
      @include fontello();
      text-decoration: none;
      line-height: 1.5;
      cursor: pointer;
    }
  }
}

.link-callout-bright-orange {
  .link-callout {
    background-color: $wcm-bright-orange;
  }
}

/* =Report Callout
----------------------------------------------------------*/
.report-callout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 365px;
  color: $wcm-white;

  .report-callout-bg-blend {
    position: relative;
    text-align: center;

    @include breakpoint($md) {
      max-width: 60%;
    }
    @include breakpoint($sm) {
      max-width: 60%;
    }
    @include breakpoint($xs) {
      max-width: 90%;
    }

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      z-index: 1;
      background-color: $wcm-dark-orange;
      mix-blend-mode: multiply;
    }

    .report-callout-inner {
      position: relative;
      z-index: 2;

      h2 {
        color: $wcm-white;
        margin-top: 25px;
      }
      .field-report-callout-description {
        margin: 10px 25px 20px 25px;
        @include breakpoint($md) {
          font-size: 20px;
        }
      }
      .field-report-callout-link {
        margin-bottom: 25px;

        a {
          line-height: 1.5;
          color: $wcm-white;
          border-color: $wcm-white;
        }
      }
    }
  }
}

/* =Hero Callout
----------------------------------------------------------*/
.hero-callout {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-size: cover;
  background-position: center;
  height: 430px;
  color: $wcm-white;

  @include breakpoint($xs) {
    height: 100px;
    margin: 0 15px 235px;
  }

  .container {
    @include breakpoint($xs) {
      padding: 0;
    }
  }

  .hero-callout-bg-blend {
    position: relative;
    padding: 10px 30px;
    float: right;

    @include breakpoint($md) {
      max-width: 35%;
    }
    @include breakpoint($sm) {
      max-width: 35%;
    }
    @include breakpoint($xs) {
      bottom: -150px;
    }

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      z-index: 1;
      background-color: $wcm-dark-orange;
      @include breakpoint($sm) {
        mix-blend-mode: multiply;
      }
    }

    .hero-callout-inner {
      position: relative;
      z-index: 2;

      h2 {
        color: $wcm-white;
        margin-top: 25px;
        font-family: $wcm-regular;
        font-weight: 400;

        @include breakpoint($xs) {
          font-family: $wcm-regular;
        }
      }
      hr {
        border-top: 1px solid $wcm-bright-orange;
        width: 20%;
        margin-left: 0;
        margin-bottom: 20px;
      }
      .field-hero-callout-description {
        margin-bottom: 25px;
        @include breakpoint($md) {
          font-size: 15px;
        }
      }
      .field-hero-callout-link {
        a {
          color: $wcm-white;
          border-color: $wcm-white;
        }
      }
    }
  }
}

/* =Generic Callout
----------------------------------------------------------*/
.generic-callout {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $wcm-white;
  margin-top: 25px;
  margin-bottom: 50px;

  @include breakpoint($md) {
    margin-top: 50px;
  }

  @include breakpoint($xs) {
    padding-top: 25px;
  }

  h4 {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: $wcm-med-gray;
  }
  hr {
    width: 8rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    height: 2px;
    border: none;
    background-color: $wcm-border-gray;
  }

  .field-generic-callout-desc {
    @include breakpoint($md) {
      max-width: 395px;
    }
    margin-bottom: 25px;
    font-size: 15px;
  }
  .field-generic-callout-link {
    .btn--wcm {
      color: $wcm-white;
      background-color: $wcm-dark-orange;
      border-color: $wcm-dark-orange;

      &:before {
        line-height: 35px;
      }
      &:hover {
        background-color: $wcm-red;
        border-color: $wcm-red;
      }
    }
  }
}

/* =Image Callout
----------------------------------------------------------*/
.image-callout-wrap {
  position: relative;
  margin-bottom: 25px;

  .image-callout {
    min-height: 380px;
    height: 380px;

    @include breakpoint($xs) {
      min-height: 85px;
      height: 85px;
      margin: 0 10px;
    }

    .image-callout-bg-blend {
      position: relative;
      text-align: center;
      height: inherit;

      &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        z-index: 1;
        background-color: $wcm-bright-orange;
        mix-blend-mode: multiply;
      }

      .image-callout-inner {
        position: relative;
        z-index: 2;
        height: inherit;
      }
    }
  }

  .embedded-generic-callout {
    z-index: 3;

    @include breakpoint($md) {
      margin-top: -10%;
    }

    .generic-callout {
      max-height: 265px;

      @include breakpoint($xs) {
        padding: 0;
        margin: 0;
      }
      @include breakpoint($md) {
        padding: 25px;
        box-shadow: 1px 2px 2px 1px $wcm-border-gray;
      }
      @include breakpoint($lg) {
        margin: 0 25px;
      }

      h4 {
        /*letter-spacing: 2px;*/
        a {
          color: $wcm-med-gray;
          font-size: 16px;

          @include breakpoint($md) {
            white-space: nowrap;
          }

          @include breakpoint($lg) {
            font-size: 18px;
          }

          &:after {
            margin-top: -5px;
          }
        }
      }
    }
  }
}
