/**
 * Example Stub for creating WCMC Subthemes
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
/**
 * Shared styles to apply to different components
 *
 */
/* =Teaser Styles
----------------------------------------------------------*/
/**
 * Styles targeting various site elements
 *
 */
/* =Typography
 ----------------------------------------------------------*/
/**
 * Tabs
 *
 * These are here mostly to override system tab styles.
 */
#main-content .ui-tabs .ui-tabs-panel {
  padding: 1em 0;
}

#main-content .ui-widget {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}

#main-content .ui-widget-header {
  background: none;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}

@media screen and (min-width: 992px) {
  #main-content .ui-widget-header {
    font-size: 16px;
  }
}

#main-content .ui-widget-content {
  background: none;
}

#main-content .ui-widget-content a {
  color: #cf4520;
}

#main-content .ui-tabs .ui-tabs-nav {
  border-bottom: 1px solid #cfd1cb !important;
}

#main-content .ui-tabs .ui-tabs-nav li {
  width: 40%;
}

@media screen and (max-width: 767px) {
  #main-content .ui-tabs .ui-tabs-nav li {
    white-space: normal;
  }
}

@media screen and (min-width: 768px) {
  #main-content .ui-tabs .ui-tabs-nav li {
    width: 32%;
  }
}

#main-content .ui-tabs .ui-tabs-nav li.ui-state-default {
  background: none;
}

#main-content .ui-tabs .ui-tabs-nav li.ui-state-active,
#main-content .ui-tabs .ui-tabs-nav li.ui-state-hover,
#main-content .ui-tabs .ui-tabs-nav li.ui-tabs-selected {
  background: #f3fafc;
  background-image: none;
}

#main-content .ui-tabs .ui-tabs-nav li.ui-state-active,
#main-content .ui-tabs .ui-tabs-nav li.ui-tabs-selected {
  border: 1px solid #cfd1cb !important;
  border-width: 1px 1px 0 !important;
  font-weight: 700;
}

#main-content .ui-corner-all, #main-content .ui-corner-top, #main-content .ui-corner-right, #main-content .ui-corner-tr {
  -moz-border-radius-topright: 0px;
  -webkit-border-top-right-radius: 0px;
  -khtml-border-top-right-radius: 0px;
  border-top-right-radius: 0px;
}

#main-content .ui-corner-all, #main-content .ui-corner-top, #main-content .ui-corner-left, #main-content .ui-corner-tl {
  -moz-border-radius-topleft: 0px;
  -webkit-border-top-left-radius: 0px;
  -khtml-border-top-left-radius: 0px;
  border-top-left-radius: 0px;
}

#main-content .ui-corner-all, #main-content .ui-corner-bottom, #main-content .ui-corner-left, #main-content .ui-corner-bl {
  -moz-border-radius-bottomleft: 0px;
  -webkit-border-bottom-left-radius: 0px;
  -khtml-border-bottom-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

#main-content .ui-corner-all, #main-content .ui-corner-bottom, #main-content .ui-corner-right, #main-content .ui-corner-br {
  -moz-border-radius-bottomright: 0px;
  -webkit-border-bottom-right-radius: 0px;
  -khtml-border-bottom-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

#main-content .ui-tabs .ui-tabs-nav li a {
  width: 100%;
  text-align: center;
}

#main-content .item-list .ui-tabs-nav li a:after {
  content: '';
}

/**
 * Styles targeting the Paragraph bundles used on the site
 *
 */
/* =Misc
----------------------------------------------------------*/
.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}

/* =Sections
----------------------------------------------------------*/
.paragraph-section__title {
  border-top: 5px solid #b31b1b;
  text-align: center;
}

.paragraph-section__title span {
  display: inline-block;
  background: #b31b1b;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin: -1px 0 0;
  padding: 5px 20px;
  letter-spacing: 2px;
}

@media screen and (min-width: 992px) {
  .paragraph-section__title span {
    margin: 0;
  }
}

/* =Bar Callouts
----------------------------------------------------------*/
@media screen and (max-width: 767px) {
  .bar-callout .row.is-flex {
    display: block;
  }
}

@media screen and (min-width: 992px) {
  .bar-callout .container {
    padding-right: 0;
    padding-left: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .bar-callout .row {
    min-height: 315px;
  }
  .bar-callout .row img {
    min-height: 315px;
  }
}

.bar-panel--title a:after {
  content: "\e80d";
  color: #fff;
}

@media screen and (min-width: 992px) {
  .bar-panel--title a:after {
    line-height: 2;
  }
}

@media screen and (min-width: 768px) {
  .bar-panel--title a:after {
    line-height: 1.6;
  }
}

.bar-panel--title--linked {
  cursor: pointer;
}

.panel-pane:nth-child(odd) .bar-panel--title {
  background-color: #cf4520;
}

.panel-pane:nth-child(even) .bar-panel--title {
  background-color: #e7751d;
}

.bar-callout .row:hover .bar-callout__image img {
  transform: scale(1.1);
}

.bar-callout__text {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .bar-callout__text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin: 0;
    font-size: 13px;
  }
}

@media screen and (min-width: 992px) {
  .bar-callout__text {
    font-size: 15px;
  }
}

.bar-callout__text a .external-link:after {
  display: inline-block;
  content: '' !important;
  background-image: url("/profiles/wcmc/themes/wcm_brand_base/images/cta_icons/SVG/external-link.svg");
  background-size: 10px 10px;
  height: 10px;
  width: 10px;
  margin-left: 6px;
  position: relative;
  top: 2px;
}

@media screen and (min-width: 992px) {
  .bar-callout__text a .external-link:after {
    background-size: 15px 15px;
    height: 15px;
    width: 15px;
  }
}

.bar-callout__text a:after {
  color: #fff;
  line-height: 1.6;
}

@media screen and (max-width: 767px) {
  .bar-callout__text {
    height: 180px;
  }
  .bar-callout__text h4 a {
    color: #fff;
  }
}

.bar-callout__title {
  font-size: 30px;
}

@media screen and (max-width: 767px) {
  .bar-callout__title {
    margin: 0 25px 0;
  }
}

@media screen and (min-width: 992px) {
  .bar-callout__title {
    margin-top: 0;
    padding-bottom: 5px;
  }
}

.bar-callout__title a {
  color: #fff;
}

.bar-callout__title a:hover {
  text-decoration: underline;
}

.bar-callout__description {
  color: #fff;
  margin: 5px 0 0;
}

@media screen and (min-width: 992px) {
  .bar-callout__description {
    font-size: 14px;
    line-height: 1.8;
  }
}

.bar-callout__description:hover {
  text-decoration: underline;
}

.bar-callout__image {
  overflow: hidden;
}

.bar-callout__image a {
  display: block;
}

@media screen and (min-width: 768px) {
  .bar-callout__image a {
    border-bottom: none;
  }
}

.bar-callout__image img {
  width: 100%;
  transition: all 0.3s ease 0s;
  padding: 0;
}

.bar-callout__image img:hover {
  transform: scale(1.1);
}

/* =Link Callout
----------------------------------------------------------*/
.link-callout {
  position: relative;
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.link-callout a {
  display: flex;
  color: #fff;
  width: 100%;
  overflow: hidden;
  align-content: center;
  align-items: center;
}

.link-callout a:after {
  color: #fff;
}

.link-callout a img {
  width: 100%;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
}

.link-callout a:hover img {
  transform: scale(1.1);
}

.link-callout h3 {
  position: absolute;
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
  font-size: 26px;
  margin: 0;
  padding: 0;
  width: 100%;
  color: #fff;
  line-height: 1.5;
  cursor: pointer;
  pointer-events: none;
}

.link-callout h3:after {
  content: '\e80d';
  color: #fff;
  font-size: 60%;
  vertical-align: middle;
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  text-decoration: none;
  line-height: 1.5;
  cursor: pointer;
}

.link-callout-bright-orange .link-callout {
  background-color: #e7751d;
}

/* =Report Callout
----------------------------------------------------------*/
.report-callout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 365px;
  color: #fff;
}

.report-callout .report-callout-bg-blend {
  position: relative;
  text-align: center;
}

@media screen and (min-width: 992px) {
  .report-callout .report-callout-bg-blend {
    max-width: 60%;
  }
}

@media screen and (min-width: 768px) {
  .report-callout .report-callout-bg-blend {
    max-width: 60%;
  }
}

@media screen and (max-width: 767px) {
  .report-callout .report-callout-bg-blend {
    max-width: 90%;
  }
}

.report-callout .report-callout-bg-blend::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  z-index: 1;
  background-color: #cf4520;
  mix-blend-mode: multiply;
}

.report-callout .report-callout-bg-blend .report-callout-inner {
  position: relative;
  z-index: 2;
}

.report-callout .report-callout-bg-blend .report-callout-inner h2 {
  color: #fff;
  margin-top: 25px;
}

.report-callout .report-callout-bg-blend .report-callout-inner .field-report-callout-description {
  margin: 10px 25px 20px 25px;
}

@media screen and (min-width: 992px) {
  .report-callout .report-callout-bg-blend .report-callout-inner .field-report-callout-description {
    font-size: 20px;
  }
}

.report-callout .report-callout-bg-blend .report-callout-inner .field-report-callout-link {
  margin-bottom: 25px;
}

.report-callout .report-callout-bg-blend .report-callout-inner .field-report-callout-link a {
  line-height: 1.5;
  color: #fff;
  border-color: #fff;
}

/* =Hero Callout
----------------------------------------------------------*/
.hero-callout {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-size: cover;
  background-position: center;
  height: 430px;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .hero-callout {
    height: 100px;
    margin: 0 15px 235px;
  }
}

@media screen and (max-width: 767px) {
  .hero-callout .container {
    padding: 0;
  }
}

.hero-callout .hero-callout-bg-blend {
  position: relative;
  padding: 10px 30px;
  float: right;
}

@media screen and (min-width: 992px) {
  .hero-callout .hero-callout-bg-blend {
    max-width: 35%;
  }
}

@media screen and (min-width: 768px) {
  .hero-callout .hero-callout-bg-blend {
    max-width: 35%;
  }
}

@media screen and (max-width: 767px) {
  .hero-callout .hero-callout-bg-blend {
    bottom: -150px;
  }
}

.hero-callout .hero-callout-bg-blend::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  z-index: 1;
  background-color: #cf4520;
}

@media screen and (min-width: 768px) {
  .hero-callout .hero-callout-bg-blend::before {
    mix-blend-mode: multiply;
  }
}

.hero-callout .hero-callout-bg-blend .hero-callout-inner {
  position: relative;
  z-index: 2;
}

.hero-callout .hero-callout-bg-blend .hero-callout-inner h2 {
  color: #fff;
  margin-top: 25px;
  font-family: "1898Sans-Regular", sans-serif;
  font-weight: 400;
}

@media screen and (max-width: 767px) {
  .hero-callout .hero-callout-bg-blend .hero-callout-inner h2 {
    font-family: "1898Sans-Regular", sans-serif;
  }
}

.hero-callout .hero-callout-bg-blend .hero-callout-inner hr {
  border-top: 1px solid #e7751d;
  width: 20%;
  margin-left: 0;
  margin-bottom: 20px;
}

.hero-callout .hero-callout-bg-blend .hero-callout-inner .field-hero-callout-description {
  margin-bottom: 25px;
}

@media screen and (min-width: 992px) {
  .hero-callout .hero-callout-bg-blend .hero-callout-inner .field-hero-callout-description {
    font-size: 15px;
  }
}

.hero-callout .hero-callout-bg-blend .hero-callout-inner .field-hero-callout-link a {
  color: #fff;
  border-color: #fff;
}

/* =Generic Callout
----------------------------------------------------------*/
.generic-callout {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  margin-top: 25px;
  margin-bottom: 50px;
}

@media screen and (min-width: 992px) {
  .generic-callout {
    margin-top: 50px;
  }
}

@media screen and (max-width: 767px) {
  .generic-callout {
    padding-top: 25px;
  }
}

.generic-callout h4 {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #666666;
}

.generic-callout hr {
  width: 8rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  height: 2px;
  border: none;
  background-color: #dddddd;
}

.generic-callout .field-generic-callout-desc {
  margin-bottom: 25px;
  font-size: 15px;
}

@media screen and (min-width: 992px) {
  .generic-callout .field-generic-callout-desc {
    max-width: 395px;
  }
}

.generic-callout .field-generic-callout-link .btn--wcm {
  color: #fff;
  background-color: #cf4520;
  border-color: #cf4520;
}

.generic-callout .field-generic-callout-link .btn--wcm:before {
  line-height: 35px;
}

.generic-callout .field-generic-callout-link .btn--wcm:hover {
  background-color: #b31b1b;
  border-color: #b31b1b;
}

/* =Image Callout
----------------------------------------------------------*/
.image-callout-wrap {
  position: relative;
  margin-bottom: 25px;
}

.image-callout-wrap .image-callout {
  min-height: 380px;
  height: 380px;
}

@media screen and (max-width: 767px) {
  .image-callout-wrap .image-callout {
    min-height: 85px;
    height: 85px;
    margin: 0 10px;
  }
}

.image-callout-wrap .image-callout .image-callout-bg-blend {
  position: relative;
  text-align: center;
  height: inherit;
}

.image-callout-wrap .image-callout .image-callout-bg-blend::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  z-index: 1;
  background-color: #e7751d;
  mix-blend-mode: multiply;
}

.image-callout-wrap .image-callout .image-callout-bg-blend .image-callout-inner {
  position: relative;
  z-index: 2;
  height: inherit;
}

.image-callout-wrap .embedded-generic-callout {
  z-index: 3;
}

@media screen and (min-width: 992px) {
  .image-callout-wrap .embedded-generic-callout {
    margin-top: -10%;
  }
}

.image-callout-wrap .embedded-generic-callout .generic-callout {
  max-height: 265px;
}

@media screen and (max-width: 767px) {
  .image-callout-wrap .embedded-generic-callout .generic-callout {
    padding: 0;
    margin: 0;
  }
}

@media screen and (min-width: 992px) {
  .image-callout-wrap .embedded-generic-callout .generic-callout {
    padding: 25px;
    box-shadow: 1px 2px 2px 1px #dddddd;
  }
}

@media screen and (min-width: 1200px) {
  .image-callout-wrap .embedded-generic-callout .generic-callout {
    margin: 0 25px;
  }
}

.image-callout-wrap .embedded-generic-callout .generic-callout h4 {
  /*letter-spacing: 2px;*/
}

.image-callout-wrap .embedded-generic-callout .generic-callout h4 a {
  color: #666666;
  font-size: 16px;
}

@media screen and (min-width: 992px) {
  .image-callout-wrap .embedded-generic-callout .generic-callout h4 a {
    white-space: nowrap;
  }
}

@media screen and (min-width: 1200px) {
  .image-callout-wrap .embedded-generic-callout .generic-callout h4 a {
    font-size: 18px;
  }
}

.image-callout-wrap .embedded-generic-callout .generic-callout h4 a:after {
  margin-top: -5px;
}

/**
 * Styles targeting the News & Updates section
 *
 */
/* =News Image Teasers
----------------------------------------------------------*/
.image-teaser {
  margin: 0 0 20px;
  padding: 0 0 20px;
  border-bottom: 1px solid #dddddd;
}

.image-teaser:last-child {
  border: none;
}

.image-teaser__title {
  font-size: 20px;
  padding: 0 0 10px;
  color: #b31b1b;
  font-family: "1898Sans-Bold", sans-serif;
}

.image-teaser__title a {
  color: #b31b1b;
}

.image-teaser__title a {
  color: #b31b1b;
}

.image-teaser__title a:after {
  content: '\e80d';
  color: #e7751d;
  padding-left: 10px;
  font-size: 60%;
  vertical-align: top;
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  text-decoration: none;
  line-height: 2;
}

.image-teaser__date {
  text-transform: uppercase;
  color: #666666;
  font-family: "1898Sans-Bold", sans-serif;
  font-size: 13px;
  letter-spacing: 2px;
  padding-bottom: 10px;
}

.brand__lockup a img {
  height: 86px;
  width: auto;
}

#primary-nav .level-1 {
  width: auto;
  padding: 8px 27px;
}

@media screen and (min-width: 992px) {
  #primary-nav .level-1 {
    padding: 8px 23px;
  }
}

#primary-nav .level-1:last-child {
  width: 11%;
}

#primary-nav .level-1:first-child {
  width: auto;
}

@media screen and (min-width: 992px) {
  #top-nav .level-2 {
    padding-right: 10px;
  }
}

#block-menu-block-1 .content {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-gap: 15px;
  -moz-column-gap: 15px;
  column-gap: 15px;
  margin-bottom: 10px;
}

.homeheader .pane-bundle-text {
  display: block;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .homeheader .pane-bundle-text {
    max-width: 720px;
  }
}

@media screen and (min-width: 992px) {
  .homeheader .pane-bundle-text {
    max-width: 940px;
  }
}

@media screen and (min-width: 1200px) {
  .homeheader .pane-bundle-text {
    max-width: 1140px;
  }
}

.homeheader .peds-home-callout {
  min-height: 485px;
  background-position: right top;
  background-size: cover;
  background-repeat: none;
}

@media screen and (min-width: 768px) {
  .homeheader .peds-home-callout {
    background-position: center;
  }
}

.homeheader .peds-home-callout .callout-text {
  min-height: 485px;
  background-color: rgba(255, 255, 255, 0.7);
  float: left;
  width: 100%;
  height: 495px;
  margin-top: 0;
}

@media screen and (min-width: 768px) {
  .homeheader .peds-home-callout .callout-text {
    width: 50%;
  }
}

.homeheader .peds-home-callout .callout-text h2 {
  font-size: 36px;
  line-height: 1.5;
  font-family: 'Open Sans', sans-seriff;
  font-weight: 200;
  color: #cf4520;
  opacity: 1;
  padding: 80px 20px 0 35px;
}

@media screen and (min-width: 992px) {
  .homeheader .peds-home-callout .callout-text h2 {
    line-height: 64px;
    font-size: 48px;
  }
}

.OrangeBar {
  display: block;
  background-color: #e7751d;
  text-align: center;
  margin-top: 30px;
  padding: 0 20px;
}

.OrangeBar select {
  color: #000;
  max-width: 940px;
  width: 100% !important;
  margin: 20px 0;
  height: 50px;
  border: 1px solid #dddddd;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding: 0 50px 0 20px;
}

.callcta {
  min-height: 135px;
  line-height: 1.5;
  padding: 46px 20px;
  text-align: center;
  font-size: 26px;
}

.callcta .btn--wcm {
  border-radius: 25px;
  padding: 8px 35px;
  font-size: 18px;
  min-width: 280px;
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .callcta .btn--wcm {
    display: inline;
    margin-right: 23px;
    margin-bottom: 0;
  }
}

.foottwo1, .foottwo2 {
  margin-top: 90px;
  margin-bottom: 80px;
}

@media screen and (min-width: 992px) {
  .foottwo1 {
    border-right: 1px solid #e6e7e8;
    padding-left: 120px;
  }
}

@media screen and (min-width: 992px) {
  .foottwo2 {
    padding-left: 100px;
  }
}

.ctarow .col-md-4 {
  overflow: hidden;
}

.ctarow h2 {
  float: left;
  width: 100%;
  text-align: center;
  height: 350px;
  margin-top: 0;
  font-size: 42px;
  text-shadow: 2px 2px #666;
}

.ctarow h2 a {
  padding-top: 260px;
  display: block;
  width: 100%;
  height: 100%;
  float: left;
  color: #fff;
}

.ctarow h2 a:after {
  display: none;
}

.ctarow h2 a:hover {
  transform: scale(1.1);
}

.ctarow .famcta h2 a {
  background: url("../images/familes2.jpg") no-repeat center center;
  background-size: cover;
}

.ctarow .rescta h2 a {
  background: url("../images/research1.jpg") no-repeat center center;
  background-size: cover;
}

.ctarow .edcta h2 a {
  background: url("../images/edu1.jpg") no-repeat 0 0;
  background-size: cover;
}

.footer3rd1 {
  padding: 0;
}

.footer3rd2 {
  padding: 0;
}

.footer3rd3 {
  padding: 0;
}

.btn--sml, .webform-submit {
  display: inline-block;
  background-color: #cf4520;
  border-radius: 7px;
  height: 30px;
  line-height: 30px;
  padding: 0 14px;
  color: #fff;
  margin-right: 10px;
  font-size: 12px;
}

.btn--sml:hover, .webform-submit:hover {
  background-color: #e7751d;
}

.webform-submit {
  padding-right: 35px;
  padding-left: 35px;
}

@media screen and (min-width: 992px) {
  .lgcol {
    max-width: 1306px;
    margin: 0 auto 55px;
  }
}

@media screen and (min-width: 992px) {
  .lgcol .col-md-8 {
    padding: 0 5px 0 0;
  }
}

@media screen and (min-width: 992px) {
  .lgcol .col-md-4 {
    padding: 0 0 0 5px;
  }
}

.pane-news-panel-pane-5 .view-teaser {
  margin-bottom: 14px;
}

.pane-news-panel-pane-5 .teaser-image {
  float: left;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .pane-news-panel-pane-5 .teaser-image {
    width: 48%;
    margin: 0 0 0 14px;
  }
}

@media screen and (min-width: 1200px) {
  .pane-news-panel-pane-5 .teaser-image {
    width: 49%;
  }
}

.pane-news-panel-pane-5 .teaser-image img {
  width: 100%;
}

.pane-news-panel-pane-5 .views-field-nothing {
  float: left;
  overflow: hidden;
  background-color: #f6f7f8;
  min-height: 403px;
  width: 100%;
  padding: 20px 20px 0;
}

.pane-news-panel-pane-5 .views-field-nothing p.post-date {
  padding: 0 0 0 40px;
}

@media screen and (min-width: 768px) {
  .pane-news-panel-pane-5 .views-field-nothing {
    padding: 7% 0 0 6.2%;
    width: 48%;
    min-height: 0;
  }
}

@media screen and (min-width: 992px) {
  .pane-news-panel-pane-5 .views-field-nothing {
    padding: 4% 0 0 5%;
  }
  .pane-news-panel-pane-5 .views-field-nothing h3.teaser-title {
    font-size: 16px;
  }
  .pane-news-panel-pane-5 .views-field-nothing p, .pane-news-panel-pane-5 .views-field-nothing p.post-date {
    font-size: 13px;
  }
  .pane-news-panel-pane-5 .views-field-nothing a.btn {
    margin-top: 10px;
  }
}

@media screen and (min-width: 1200px) {
  .pane-news-panel-pane-5 .views-field-nothing {
    padding: 7% 0 0 6.2%;
    width: 49%;
  }
  .pane-news-panel-pane-5 .views-field-nothing h3.teaser-title {
    font-size: 20px;
  }
  .pane-news-panel-pane-5 .views-field-nothing p {
    font-size: 18px;
  }
  .pane-news-panel-pane-5 .views-field-nothing p.post-date {
    font-size: 14px;
  }
  .pane-news-panel-pane-5 .views-field-nothing a.btn {
    margin-top: 17.5px;
  }
}

.pane-news-panel-pane-5 h3.teaser-title {
  font-size: 20px;
  line-height: 1.5;
  margin-top: 0;
}

.pane-news-panel-pane-5 h3.teaser-title a {
  color: #cf4520;
}

.pane-news-panel-pane-5 h3.teaser-title a:after {
  display: none;
}

.pane-news-panel-pane-5 h3.teaser-title a:before {
  content: " ";
  float: left;
  margin: 0 10px 120px 0;
  width: 30px;
  height: 30px;
  background: url("../images/newssprite.png") 0 0 no-repeat;
  background-size: 30px 30px;
}

.pane-news-panel-pane-5 p.post-date {
  color: #7a7b7c;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  padding: 25px 0 0;
}

.pane-news-panel-pane-5 p {
  padding: 0 40px 0 40px;
  font-size: 18px;
  line-height: 1.5;
}

.pane-news-panel-pane-5 a.btn {
  margin-top: 17.5px;
  margin-left: 40px;
}

.pane-news-panel-pane-5 .views-row-last .views-field-nothing {
  float: right;
}

@media screen and (min-width: 768px) {
  .pane-news-panel-pane-5 .views-row-last .views-field-nothing {
    margin-right: 4px;
  }
}

@media screen and (min-width: 768px) {
  .pane-news-panel-pane-5 .views-row-last .teaser-image {
    margin: 0 0 10px;
  }
}

.pane-events-panel-pane-1 .views-row {
  float: left;
  height: 403px;
  margin: 0 0 14px 0;
  background-color: #f6f7f8;
  padding: 20px;
  width: 100%;
  overflow: hidden;
}

.pane-events-panel-pane-1 .views-row .views-field-field-events-date,
.pane-events-panel-pane-1 .views-row .views-field-field-date-and-time {
  padding-left: 40px;
  text-transform: uppercase;
  color: #7a7b7c;
  font-weight: bold;
  margin: 0 0 20px 0;
}

.pane-events-panel-pane-1 .views-row .views-field-body {
  padding-left: 40px;
  font-size: 18px;
}

.pane-events-panel-pane-1 .views-row .views-field-nothing {
  padding-left: 40px;
  margin-top: 17.5px;
}

.pane-events-panel-pane-1 .views-row h3 {
  margin-top: 0;
}

.pane-events-panel-pane-1 .views-row h3 a {
  font-size: 18px;
  line-height: 1.5;
  color: #cf4520;
}

.pane-events-panel-pane-1 .views-row h3 a:after {
  display: none;
}

.pane-events-panel-pane-1 .views-row h3 a:before {
  content: " ";
  float: left;
  margin: 0 10px 120px 0;
  width: 30px;
  height: 30px;
  background: url("../images/calsprite.png") 0 0 no-repeat;
  background-size: 30px 30px;
}

@media screen and (min-width: 992px) {
  .pane-events-panel-pane-1 .views-row {
    padding: 35px 0 0 30px;
  }
  .pane-events-panel-pane-1 .views-row h3 a {
    font-size: 16px;
  }
  .pane-events-panel-pane-1 .views-row .views-field-body {
    font-size: 13px;
  }
  .pane-events-panel-pane-1 .views-row .views-field-field-events-date {
    margin-bottom: 10px;
  }
  .pane-events-panel-pane-1 .views-row .views-field-field-events-date .field-content {
    font-size: 13px;
  }
}

@media screen and (min-width: 1200px) {
  .pane-events-panel-pane-1 .views-row {
    padding: 50px 20px 0 45px;
  }
  .pane-events-panel-pane-1 .views-row h3 a {
    font-size: 18px;
  }
  .pane-events-panel-pane-1 .views-row .views-field-body {
    font-size: 18px;
  }
  .pane-events-panel-pane-1 .views-row .views-field-field-events-date {
    margin-bottom: 20px;
  }
  .pane-events-panel-pane-1 .views-row .views-field-field-events-date .field-content {
    font-size: 14px;
  }
}

/* RHS column style */
.pane-wcm-peds-serappbtn,
.pane-wcm-peds-servicesbtn,
.pane-wcm-peds-serctsbtn {
  margin-top: 20px;
  float: left;
  width: 100%;
}

@media screen and (min-width: 992px) {
  .pane-wcm-peds-serappbtn,
  .pane-wcm-peds-servicesbtn,
  .pane-wcm-peds-serctsbtn {
    margin-top: 0;
  }
}

.pane-wcm-peds-serappbtn .btn,
.pane-wcm-peds-servicesbtn .btn,
.pane-wcm-peds-serctsbtn .btn {
  margin: 0 auto 13px;
  display: block;
}

.pane-wcm-peds-socbtns {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}

.pane-wcm-peds-socbtns .socbtns {
  width: 220px;
  margin: 0 auto;
}

.pane-wcm-peds-socbtns .socbtns:before, .pane-wcm-peds-socbtns .socbtns::after {
  content: " ";
  display: table;
}

.pane-wcm-peds-socbtns .socbtns .wcm-cta__tile {
  height: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pane-node-field-weillcornell-org-page {
  width: 100%;
  float: left;
}

.information-sidebar {
  margin-top: -35px;
}

.information-sidebar .pane-custom.pane-1 {
  width: 100%;
  float: left;
  margin: 0 0 0 20px;
  min-height: none;
}

.information-sidebar .pane-custom.pane-1 .pane-title {
  margin: 10px 0 5px;
  color: #666666;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.information-sidebar .pane-custom.pane-1 a {
  font-size: 12px;
  line-height: 32px;
}

.pad10b {
  padding-bottom: 10px;
}

.contact-info span {
  line-height: 24px;
}

.contact-info .site-name {
  margin-top: 30px;
}

.contact-info .titleline {
  margin-top: 30px;
}

.wcm-cta {
  margin-bottom: 0px;
}

.wcm-cta .wcm-cta__tile {
  border-width: 0;
  width: 73px;
  height: 73px;
  margin-top: 10px;
}

.wcm-cta .mailcta svg {
  margin-bottom: 2px;
  margin-top: 2px;
}

.wcm-cta span.lnktxt {
  margin-top: 5px;
}

.jump-to-top {
  margin-top: 5px;
}

.jump-to-top > a {
  border-top: 1px solid #d7d8d9;
  padding-top: 18px;
}

.jump-to-top > a span {
  border-top: 0;
}

/* Clincial Services */
.btn--orglnk {
  margin-bottom: 20px;
  background: url("../images/orglnk.svg") no-repeat 175px 5px;
  background-size: 23px;
}

.btn--orglnk:hover {
  background: url("../images/orglnkw.svg") no-repeat 175px 5px #e7751d;
  background-size: 23px;
}

.btn--orglnk:hover:before {
  opacity: 0;
}

#views-exposed-form-profiles-panel-pane-4 .select2-container {
  width: 400px !important;
  max-width: 400px !important;
}

.pane-node-field-services-programs,
.pane-node-field-diagnosis-treatment,
.pane-node-field-what-to-expect,
.pane-profiles-panel-pane-3,
.pane-node-field-related-research,
.pane-node-field-curriculum,
.pane-node-field-research,
.pane-profiles-panel-pane-5,
.pane-node-field-benefits,
.pane-node-field-resources,
.pane-node-field-what-sets-us-apart {
  border-bottom: 5px solid #d7d8d9;
  margin-bottom: 48px;
  padding-bottom: 30px;
}

.pane-node-field-curriculum {
  border-top: 5px solid #d7d8d9;
  margin-top: 48px;
  padding-top: 26px;
}

.pane-profiles-panel-pane-5 h3 {
  margin-bottom: 30px;
}

.pane-node-field-how-to-apply {
  margin-bottom: 40px;
}

.pane-profiles-panel-pane-3 {
  margin-top: 30px;
}

.pane-node-field-related-research h3 {
  margin-bottom: 30px;
}

.pane-node-field-related-research h4 {
  margin-top: 30px;
}

.pane-profiles-panel-pane-3 .row, .pane-profiles-panel-pane-5 .row {
  margin: 0;
}

.pane-profiles-panel-pane-3 .views-field-field-professional-title, .pane-profiles-panel-pane-5 .views-field-field-professional-title {
  font-size: 13px;
}

.pane-node-field-resources h3 {
  margin-bottom: 20px;
}

/* About Us */
.pane-news-panel-pane-6 h3,
.pane-news-panel-pane-7 h3,
.pane-news-panel-pane-8 h3,
.pane-news-panel-pane-9 h3,
.pane-news-panel-pane-10 h3,
.pane-news-panel-pane-12 h3,
.pane-news-panel-pane-13 h3 {
  border-top: 5px solid #dcddde;
  padding-top: 30px;
}

.pane-news-panel-pane-6 p.teaser-title,
.pane-news-panel-pane-7 p.teaser-title,
.pane-news-panel-pane-8 p.teaser-title,
.pane-news-panel-pane-9 p.teaser-title,
.pane-news-panel-pane-10 p.teaser-title,
.pane-news-panel-pane-12 p.teaser-title,
.pane-news-panel-pane-13 p.teaser-title {
  font-size: 16px;
  margin-top: 30px;
}

.pane-news-panel-pane-6 p.post-date,
.pane-news-panel-pane-6 .views-field-field-events-date,
.pane-news-panel-pane-7 p.post-date,
.pane-news-panel-pane-7 .views-field-field-events-date,
.pane-news-panel-pane-8 p.post-date,
.pane-news-panel-pane-8 .views-field-field-events-date,
.pane-news-panel-pane-9 p.post-date,
.pane-news-panel-pane-9 .views-field-field-events-date,
.pane-news-panel-pane-10 p.post-date,
.pane-news-panel-pane-10 .views-field-field-events-date,
.pane-news-panel-pane-12 p.post-date,
.pane-news-panel-pane-12 .views-field-field-events-date,
.pane-news-panel-pane-13 p.post-date,
.pane-news-panel-pane-13 .views-field-field-events-date {
  margin: 10px 0 5px;
  color: #666666;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.pane-news-panel-pane-6 .teaser-text p,
.pane-news-panel-pane-7 .teaser-text p,
.pane-news-panel-pane-8 .teaser-text p,
.pane-news-panel-pane-9 .teaser-text p,
.pane-news-panel-pane-10 .teaser-text p,
.pane-news-panel-pane-12 .teaser-text p,
.pane-news-panel-pane-13 .teaser-text p {
  margin-bottom: 0px;
}

.pane-news-panel-pane-6 .btn--sml,
.pane-news-panel-pane-7 .btn--sml,
.pane-news-panel-pane-8 .btn--sml,
.pane-news-panel-pane-9 .btn--sml,
.pane-news-panel-pane-10 .btn--sml,
.pane-news-panel-pane-12 .btn--sml,
.pane-news-panel-pane-13 .btn--sml {
  margin: 40px 0;
  background-color: #b31b1b;
}

.pane-news-panel-pane-6 .btn--sml:hover,
.pane-news-panel-pane-7 .btn--sml:hover,
.pane-news-panel-pane-8 .btn--sml:hover,
.pane-news-panel-pane-9 .btn--sml:hover,
.pane-news-panel-pane-10 .btn--sml:hover,
.pane-news-panel-pane-12 .btn--sml:hover,
.pane-news-panel-pane-13 .btn--sml:hover {
  background-color: #e7751d;
}

.pane-node-field-research-education h3.pane-title {
  color: #555;
}

.pane-node-field-related-service,
.pane-node-field-related-fellowship {
  color: #fff;
  background-color: #b31b1b;
  margin-bottom: 20px;
}

.pane-node-field-related-service a,
.pane-node-field-related-fellowship a {
  color: #fff;
  border: none;
}

.pane-node-field-contact-info1 {
  border: 5px solid #f6f7f8;
  text-align: center;
  padding-bottom: 15px;
  margin-bottom: 50px;
}

.pane-node-field-contact-info1 .content {
  font-size: 16px;
  line-height: 25px;
}

.pane-node-field-contact-info1 h3 {
  color: #545556;
  margin-top: 15px;
  text-align: center;
}

.pane-node-field-contact-info1 .entity-field-collection-item {
  border-top: 1px solid #f6f7f8;
  padding: 10px 0;
  margin: 0 20px;
}

.pane-node-field-contact-info1 .entity-field-collection-item:first-child {
  border-top: 0;
  padding-top: 0;
}

.pane-node-field-contact-info1 .entity-field-collection-item:last-child {
  padding-bottom: 0;
}

hr {
  border-top: 5px solid #dcddde;
  margin-top: 55px;
  margin-bottom: 45px;
}

.btn--xpad {
  padding: 0 45px;
  border-radius: 8px;
  font-size: 12px;
}

.btn--sml {
  font-size: 12px;
}

.pane-wcm-peds-fellowdrop h3 {
  margin-bottom: 10px;
}

.pane-wcm-peds-fellowdrop .select2 {
  max-width: 345px;
  width: 100% !important;
  margin-bottom: 30px;
}

/* Research Page */
.pane-news-news-research-pane p.post-date {
  margin: 10px 0 5px;
  color: #777;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.pane-programs-list-panel-pane-1 {
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 20px;
}

.pane-programs-list-panel-pane-1 a::after {
  content: none;
}

@media screen and (max-width: 767px) {
  .pane-programs-list-panel-pane-1 {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .pane-programs-list-panel-pane-1 {
    display: block;
  }
}

@media screen and (min-width: 992px) {
  .pane-programs-list-panel-pane-1 {
    display: none;
  }
}

.information-column .pane-programs-list-panel-pane-1 {
  border: 1px solid #ddd;
  padding: 10px 0 20px 30px;
  margin-bottom: 20px;
}

.information-column .pane-programs-list-panel-pane-1 a::after {
  content: none;
}

@media screen and (max-width: 767px) {
  .information-column .pane-programs-list-panel-pane-1 {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .information-column .pane-programs-list-panel-pane-1 {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .information-column .pane-programs-list-panel-pane-1 {
    display: block;
  }
}

.pane-wcm-peds-research-callout {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 30px 0;
}

.pane-wcm-peds-research-callout h3 {
  margin-bottom: 10px;
}

.pane-bundle-wcm-page-item .wcm-pgraph-text-teaser {
  margin: 50px 0;
}

.pane-news-news-research-pane .pane-title {
  margin-bottom: 25px;
}

.pane-news-news-research-pane .view-content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

@media screen and (min-width: 992px) {
  .pane-news-news-research-pane .view-content {
    flex-direction: row;
  }
}

.pane-news-news-research-pane .view-content .view-teaser {
  width: 100%;
}

@media screen and (min-width: 992px) {
  .pane-news-news-research-pane .view-content .view-teaser {
    flex-direction: row;
    width: 33%;
    margin: 0 20px;
  }
}

.pane-news-news-research-pane .view-content .views-row-first {
  margin-left: 0;
}

.pane-news-news-research-pane .view-content .views-row-lasat {
  margin-right: 0;
}

/* Career Opportunities Page */
.view-id-career_opportunities .views-row {
  padding-bottom: 1rem;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 3rem;
}

/* Profile List Page */
.profile-list-item__pro-title .item-list ul li {
  margin: 0;
  padding: 0;
}

/* Services Page */
@media screen and (min-width: 992px) {
  .node-type-pediatric-services .wcm-pgraph-card {
    width: 50%;
  }
}

.node-type-pediatric-services .pane-node-field-contact-info {
  margin: 25px 0;
  padding: 25px 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  text-align: center;
}

.node-type-pediatric-services .pane-node-field-contact-info .field-contact-title,
.node-type-pediatric-services .pane-node-field-contact-info .field-contact-devision {
  font-weight: bold;
}

@media screen and (min-width: 992px) {
  .pane-pediatrics-services-panel-pane-1 .col-1,
  .pane-programs-list-panel-pane-1 .col-1,
  .reslinks .col-1,
  .pane-fellowship-panel-pane-1 .col-1 {
    padding-right: 40px;
  }
}

.pane-pediatrics-services-panel-pane-1 a,
.pane-programs-list-panel-pane-1 a,
.reslinks a,
.pane-fellowship-panel-pane-1 a {
  color: #cf4520;
  font-size: 16px;
  line-height: 20px;
  width: 100%;
  display: block;
  padding: 15px 15px 10px 3px;
  border-bottom: 1px solid #ddd;
}

.pane-pediatrics-services-panel-pane-1 a:hover,
.pane-programs-list-panel-pane-1 a:hover,
.reslinks a:hover,
.pane-fellowship-panel-pane-1 a:hover {
  border-bottom: 1px solid #e7751d;
}

.pane-pediatrics-services-panel-pane-1 a:after,
.pane-programs-list-panel-pane-1 a:after,
.reslinks a:after,
.pane-fellowship-panel-pane-1 a:after {
  content: '\e80d';
  color: #e87722;
  padding-left: 10px;
  font-size: 60%;
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  display: block;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  line-height: 1.5;
  float: right;
}

.hero-image--full {
  width: 100%;
  height: 175px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 0 0 20px;
}

@media screen and (min-width: 768px) {
  .hero-image--full {
    height: 300px;
  }
}

.pane-node-field-main-image img {
  width: 100%;
  height: auto;
  margin: 0 0 20px;
}

.pane-fellowship-panel-pane-1 {
  margin-bottom: 40px;
}

.reslinks {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 80px;
  -moz-column-gap: 80px;
  column-gap: 80px;
  padding-left: 0;
  list-style: none;
  margin-top: 15px;
}

.reslinks li {
  width: 100%;
}

.reslinks li:nth-child(3) a {
  border-bottom: none;
}

.reslinks li:nth-child(6) a {
  border-bottom: none;
}

.dructa {
  margin-top: 30px;
  display: inline-block;
  clear: both;
}

.dructa .caption {
  margin-top: 0;
}

.drurow {
  margin-top: 65px;
}

.drurow h4 {
  margin: 0 0 20px 0;
}

.drurow h4 a {
  color: #555;
}

.drurow .col-md-6:first-child {
  border-right: 1px solid #d7d8d9;
  padding-right: 45px;
}

@media screen and (min-width: 992px) {
  .drurow .col-md-6:nth-child(2) {
    padding-left: 45px;
  }
}

.pane-node-webform {
  margin-top: 50px;
  margin-bottom: 77px;
}

.pane-node-webform label {
  margin-bottom: 10px;
}

.pane-node-webform .select2-container {
  width: 100% !important;
  max-width: 600px;
}

.form-control {
  max-width: none;
}

.view-display-id-panel_pane_4 {
  margin: 0;
}

#views-exposed-form-profiles-panel-pane-4 .select2-container {
  width: 265px !important;
  max-width: 265px !important;
  margin-right: 5px;
}

#views-exposed-form-profiles-panel-pane-4 .views-exposed-form label {
  font-size: 13px;
}

.pane-profiles-panel-pane-4 h4.display-name {
  font-size: 16px;
  color: #000;
  font-weight: bold;
}

.pane-profiles-panel-pane-4 .views-field-field-professional-title {
  font-size: 13px;
}

.pane-profiles-panel-pane-4 .views-field-nothing {
  font-size: 12px;
  color: #cf4520;
  margin-top: 5px;
}

.pane-profiles-panel-pane-4 .view-grouping-header {
  border-top: 5px solid #d7d8d9;
  padding-top: 40px;
  margin-bottom: 16px;
  color: #b31b1b;
  font-size: 20px;
  font-family: "1898Sans-Bold",sans-serif;
}

.pane-profiles-panel-pane-4 h3.grid-title {
  color: #545556;
}

.pane-profiles-panel-pane-4 h3.grid-title:first-child {
  display: none;
}

.pane-profiles-panel-pane-4 .view-grouping:first-child .view-grouping-header {
  border-top: none;
}

.pane-profiles-panel-pane-4 .gridcol {
  margin: 20px 0;
}

.pane-patient-stories-panel-pane-1 .grid-item {
  border-bottom: 5px solid #d7d8d9;
  max-width: 355px;
}

.pane-patient-stories-panel-pane-1 h3 {
  margin-bottom: 30px;
}

.pane-patient-stories-panel-pane-1 .views-field-field-main-image {
  margin-bottom: 30px;
}

.pane-patient-stories-panel-pane-1 blockquote {
  font-size: 15px;
  line-height: 25px;
  border-left: 3px solid #ffc72c;
  color: #000;
  padding: 0 12px;
}

.pane-patient-stories-panel-pane-1 .views-field-view-node {
  margin-bottom: 33px;
}

.pane-patient-stories-panel-pane-1 .views-field-view-node a {
  padding-left: 15px;
}

.pane-patient-stories-panel-pane-1 .views-field-view-node a:after {
  content: '\e80d';
  color: #cf4520;
  padding-left: 10px;
  font-size: 60%;
  vertical-align: top;
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  text-decoration: none;
  line-height: 2.8;
}

blockquote {
  font-size: 25px;
  line-height: 35px;
  color: #6c6d6e;
  border-left: 5px solid #ffc72c;
  padding: 0 18px;
  font-weight: normal;
}

.pane-node-field-secondary-image {
  width: 50%;
  float: left;
  margin: 25px 0 40px;
}

.pane-custom.pane-1 {
  margin: 25px 0 40px;
  width: 50%;
  float: left;
  min-height: 230px;
}

.pane-custom.pane-1 blockquote {
  margin-bottom: 0;
}

.pane-custom.pane-1 p {
  border-left: 5px solid #ffc72c;
  padding: 25px 18px 0;
  margin: 0;
  font-size: 16px;
}

.field-collection-item-field-contact-info {
  margin: 20px 0;
}

.field-collection-item-field-contact-info .content {
  text-align: center;
}

.field-collection-item-field-contact-info .content .field-name-field-contact-title,
.field-collection-item-field-contact-info .content .field-name-field-contact-devision {
  color: #6c6d6e;
  line-height: 24px;
  font-weight: 600;
}

.field-collection-item-field-contact-info .content .field-name-field-serv-address-line-1 {
  margin-top: 20px;
}

.field-collection-item-field-contact-info .content .field-label {
  display: inline;
  float: none;
  font-weight: 400;
}

.field-collection-item-field-contact-info .content .field-name-field-phone-no-,
.field-collection-item-field-contact-info .content .field-name-field-fax-no- {
  font-weight: 600;
}

.field-collection-item-field-contact-info .content .field-name-field-phone-no- .field-label,
.field-collection-item-field-contact-info .content .field-name-field-fax-no- .field-label {
  font-weight: 600;
}

.btn--wcm:hover:before {
  left: 90%;
}

#qualfrom {
  height: 1500px;
  max-width: 100%;
  border: 0;
}

/* .ctools-collapsible-handle {
  position: relative;
  border: 1px solid #ddd;
  border-width: 1px 0 0;
  cursor: pointer;
  margin: 0;
  padding: 15px 55px 15px 10px;

  h2 {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #cf4520;
    margin: 0;
    &:after {
        content: '+';
        width: 50px;
        display: block;
        position: absolute;
        top: 15px;
        right: 0;
        font-size: 30px;
        font-weight: 600;
        text-align: center;
        border-left: 1px solid #ddd;
    }
  }
} */
/* .ctools-collapsible-handle.clsbtn h2:after {
  content: '-';
} */
/* .ctools-collapsible-container .ctools-toggle-collapsed {
  background: none;
} */
.ctools-toggle {
  display: none;
}

h3.field-label {
  margin: 0;
}

.main-content table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.main-content table > thead > tr > th,
.main-content table > thead > tr > td,
.main-content table > tbody > tr > th,
.main-content table > tbody > tr > td,
.main-content table > tfoot > tr > th,
.main-content table > tfoot > tr > td {
  padding: 8px;
  line-height: 1.66667;
  vertical-align: top;
  border-top: 1px solid #ddd;
}

.main-content table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}

.main-content table > caption + thead > tr:first-child > th,
.main-content table > caption + thead > tr:first-child > td,
.main-content table > colgroup + thead > tr:first-child > th,
.main-content table > colgroup + thead > tr:first-child > td,
.main-content table > thead:first-child > tr:first-child > th,
.main-content table > thead:first-child > tr:first-child > td {
  border-top: 0;
}

.main-content table > tbody + tbody {
  border-top: 2px solid #ddd;
}

.main-content table table {
  background-color: #fff;
}

.main-content table {
  border: 1px solid #ddd;
}

.main-content table > thead > tr > th,
.main-content table > thead > tr > td,
.main-content table > tbody > tr > th,
.main-content table > tbody > tr > td,
.main-content table > tfoot > tr > th,
.main-content table > tfoot > tr > td {
  border: 1px solid #ddd;
}

.main-content table > thead > tr > th,
.main-content table > thead > tr > td {
  border-bottom-width: 2px;
}

.main-content table {
  width: 100%;
  margin: 0 0 16px 0;
  border-top-color: #f5f6f7;
}

.main-content table thead {
  border-color: #f5f6f7;
}

.main-content table thead tr {
  border-color: #f5f6f7;
}

.main-content table thead tr th {
  background-color: #f5f6f7;
  border-color: #f5f6f7;
  padding: 3px;
}

.main-content table tbody {
  border-color: #f5f6f7;
}

.main-content table tbody tr {
  background-color: #fff;
}

.main-content table tbody tr:nth-child(odd) > td {
  background-color: #fff;
}

.main-content table tbody tr td {
  border-color: #f5f6f7;
}

.pane-profiles-panel-pane-3 .views-field-field-pops-profile-link {
  margin-bottom: 20px;
}

.media-youtube-player {
  border: 0;
}

.pane-node-field-what-sets-us-apart h3 {
  margin-bottom: 20px;
}

/* =Default Select Elements
----------------------------------------------------------*/
.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid #dddddd;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px;
}

/**
 * Styles targeting the home page elements
 *
 */
/* =Home Page
 ----------------------------------------------------------*/
.pediatrics-home > .container-fluid {
  margin: 0 0 25px 0;
  padding: 0;
  margin-top: 20px;
}

.pediatrics-home > .container-fluid .row {
  margin: 0;
  padding: 0;
}

.pediatrics-home .hero-section h3 {
  padding: 0 20px;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
}

.pediatrics-home .hero-section .hero-left-section {
  padding: 0;
}

@media screen and (min-width: 992px) {
  .pediatrics-home .hero-section .hero-left-section .link-callout {
    max-height: 405px;
    height: 405px;
    margin-top: 0;
    margin: 0 5px 0 0;
  }
  .pediatrics-home .hero-section .hero-left-section .link-callout a {
    max-height: 405px;
  }
}

.pediatrics-home .hero-section .hero-right-section {
  padding: 0;
}

.pediatrics-home .hero-section .hero-right-section .hero-right-top-section {
  padding: 0;
}

@media screen and (min-width: 992px) {
  .pediatrics-home .hero-section .hero-right-section .hero-right-top-section .link-callout {
    max-height: 200px;
    height: 200px;
    margin: 0 0 2.5px 0;
  }
  .pediatrics-home .hero-section .hero-right-section .hero-right-top-section .link-callout a {
    max-height: 200px;
  }
}

.pediatrics-home .hero-section .hero-right-section .hero-right-bottom-section {
  padding: 0;
}

@media screen and (min-width: 992px) {
  .pediatrics-home .hero-section .hero-right-section .hero-right-bottom-section .link-callout {
    max-height: 200px;
    height: 200px;
    margin: 2.5px 0 0 0;
  }
  .pediatrics-home .hero-section .hero-right-section .hero-right-bottom-section .link-callout a {
    max-height: 200px;
  }
}

.pediatrics-home .visit-patient-care-site {
  text-align: center;
  margin: 50px 0;
}

@media screen and (min-width: 768px) {
  .pediatrics-home .visit-patient-care-site:before {
    content: '';
    background-image: url("../../../../../profiles/wcmc/themes/wcm_brand_base/images/cta_icons/SVG/patients.svg");
    background-size: 65px;
    background-repeat: no-repeat;
    width: 65px;
    height: 65px;
    display: inline-block;
    margin: 0 auto;
  }
}

.pediatrics-home .news .view-content {
  margin-top: 25px;
}
