/**
 * Styles targeting various site elements
 *
 */

/* =Typography
 ----------------------------------------------------------*/

@mixin wcm_external_icon() {
  display: inline-block;
  content: '' !important;
  background-image: url('/profiles/wcmc/themes/wcm_brand_base/images/cta_icons/SVG/external-link.svg');
  background-size: 10px 10px;
  height: 10px;
  width: 10px;
  margin-left: 6px;
  position: relative;
  top: 2px;
  @include breakpoint($md) {
    background-size: 15px 15px;
    height: 15px;
    width: 15px;
  }
}
