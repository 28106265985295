/**
 * Styles targeting the home page elements
 *
 */

/* =Home Page
 ----------------------------------------------------------*/
.pediatrics-home {

  > .container-fluid {
    margin: 0 0 25px 0;
    padding: 0;
    margin-top: 20px;

    .row {
      margin: 0;
      padding: 0;
    }
  }

  .hero-section {
    h3 {
      padding: 0 20px;
      text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    }

    .hero-left-section {
      padding: 0;

      @include breakpoint($md) {
        .link-callout {
          max-height: 405px;
          height: 405px;
          margin-top: 0;
          margin: 0 5px 0 0;
          a {
            max-height: 405px;
          }
        }
      }
    }
    .hero-right-section {
      padding: 0;
      .hero-right-top-section {
        padding: 0;

        @include breakpoint($md) {
          .link-callout {
            max-height: 200px;
            height: 200px;
            margin: 0 0 2.5px 0;
            a {
              max-height: 200px;
            }
          }
        }
      }
      .hero-right-bottom-section {
        padding: 0;

        @include breakpoint($md) {
          .link-callout {
            max-height: 200px;
            height: 200px;
            margin: 2.5px 0 0 0;;
            a {
              max-height: 200px;
            }
          }
        }
      }
    }
  }

  .visit-patient-care-site {
    text-align: center;
    margin: 50px 0;

    &:before {
      @include breakpoint($sm) {
        content: '';
        background-image: url('../../../../../profiles/wcmc/themes/wcm_brand_base/images/cta_icons/SVG/patients.svg');
        background-size: 65px;
        background-repeat: no-repeat;
        width: 65px;
        height: 65px;
        display: inline-block;
        margin: 0 auto;
      }
    }
  }

  .news {
    .view-content {
      margin-top: 25px;
    }
  }
}
